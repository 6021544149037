<script>
import { AuthService } from "@/services/auth";
import Message from "vue-m-message";

export default {
  name: "ResetPassword",
  data: function () {
    return {
      password1: "",
      password2: "",
      isPasswordValid: false,
      passwordError: "",
      isPasswordFocused: false,
    };
  },
  methods: {
    async handleSubmit() {
      if (!this.password1 || this.password1 !== this.password2) {
       Message.error(this.$t("Passwords are not the same"));
      }
      const { res } = await AuthService.resetPassword({
        password: this.password1,
        token: this.$route.params.token,
      });
      if (res.status === 200) {
       Message.success(this.$t("auth.change_success"));
        return this.$router.push({ name: "Management Login" });
      }
      Message.warning(this.$t("Something went wrong"));
    },
    handlePasswordChange() {
      this.isPasswordFocused = true;
      const newPassword = this.password1;
      let error = "";
      if (newPassword.length < 8) {
        error = "Password must be 8 characters.";
      } else if (!/[a-z]/.test(newPassword)) {
        error = "Password must includ at least one uppercase letter.";
      } else if (!/[A-Z]/.test(newPassword)) {
        error = "Password must include at least one lowercase letter.";
      } else if (!/\d/.test(newPassword)) {
        error = "Password must include at least one number.";
      } else if (!/[@$!%*?&]/.test(newPassword)) {
        error = "Password must include at least one special character.";
      }
      this.isPasswordValid = error === "";
      this.passwordError = error;
    },
  },
};
</script>
<template>
  <CCard class="p-4 custom-card">
    <CCardBody>
      <h1 class="form-heading">{{ $t("Reset password") }}</h1>
      <p class="text-muted form-subtext">{{ $t("Set your new password") }}</p>
      <CForm @submit.prevent="handleSubmit">
        <CInput
          :placeholder="$t('New password')"
          type="password"
          autocomplete="password"
          v-model="password1"
          name="password1"
          required
          :class="{ 'red-outline': isPasswordFocused && !isPasswordValid }"
          @input="handlePasswordChange"
        />
        <p v-if="isPasswordFocused & !isPasswordValid" class="text-danger">
          {{ passwordError }}
        </p>
        <CInput
          class="my-2 mt-3"
          :placeholder="$t('Repeat new password')"
          type="password"
          autocomplete="password"
          v-model="password2"
          name="password2"
          required
        />
        <CButton color="primary" class="px-4 form-button" type="submit">
          {{ $t("Change password") }}
        </CButton>
      </CForm>
    </CCardBody>
  </CCard>
</template>

<style scoped>
.custom-card {
  max-width: 495px;
  background-color: #ffffff;
  box-shadow: 2px 2px 10px 0px #07132b0d;
  border-radius: 10px;
  margin: 0 auto;
  position: relative;
  padding: 20px;
  box-sizing: border-box;
}
.form-heading {
  color: #0c2044;
  font-family: "Gill Sans", "Gill Sans MT", Calibri, "Trebuchet MS", sans-serif;
  font-size: 32px;
  font-weight: 700;
}
.form-subtext {
  color: #81848a;
  font-family: "Gill Sans", "Gill Sans MT", Calibri, "Trebuchet MS", sans-serif;
  font-size: 16px;
  font-weight: 400;
}
.form-button {
  width: 153px;
  border-radius: 0px 0px 0px 25px;
  margin-top: 20px;
}
</style>
